import config from './config';

const awsconfig = {
  Auth: {
    Cognito: {
      region: config.REGION,
      userPoolId: config.COGNITO_USER_POOL_ID,
      userPoolClientId: config.COGNITO_APP_CLIENT_ID,
      identityPoolId: config.COGNITO_IDENTITY_POOL_ID,
      loginWith: {
        email: false,
        username: false,
        oauth: {
          domain: config.COGNITO_DOMAIN,
          scopes: ['email', 'openid', 'profile'],
          redirectSignIn: config.COGNITO_REDIRECT_SIGN_IN.split(','),
          redirectSignOut: config.COGNITO_REDIRECT_SIGN_OUT.split(','),
          responseType: 'code',
          providers: ['Google'],
        },
      },
    },
  },
};

export default awsconfig;
