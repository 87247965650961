import React from 'react';
import logo from '../img/logo.png'; // Adjust the path accordingly

function HomePage() {
  return (
    <div className="container">
      <img src={logo} alt="Johnkins Logo" className="logo" />
      <h1 className="title">Welcome to Johnkins</h1>

      <p className="description">
        Johnkins is an application for automation test trigger management. It
        allows you to trigger autotests on various projects, generate paid
        users, monitor pipelines, and manage configurations efficiently.
      </p>
      <h2 className="subtitle">Features</h2>
      <ul className="feature-list">
        <li>Trigger autotests on different projects.</li>
        <li>Generate paid users for testing purposes.</li>
        <li>Monitor the status of pipelines in real-time.</li>
        <li>Manage and configure various settings easily.</li>
      </ul>
    </div>
  );
}

export default HomePage;
