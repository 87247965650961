import React from 'react';
import './css/InfoTooltip.css';

function InfoTooltip({ message }) {
    return (
        <div className="info-tooltip">
            <span className="info-icon">ℹ️</span>
            <span className="info-text">{message}</span>
        </div>
    );
}

export default InfoTooltip;
