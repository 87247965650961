import React, {useEffect, useState} from 'react';
import GitLabApi from '../backend/GitlabApi';
import formOptions from './formOptions.json';
import gitConstants from '../constants/gitlab.constants.json';
import InfoTooltip from '../components/InfoTooltip';
import Select from 'react-select';

function SpecRunPage() {
    const [project, setProject] = useState(formOptions.projects[0].name);
    const [server, setServer] = useState(formOptions.servers[0]);
    const [selectedBranch, setSelectedBranch] = useState('master');
    const [selectedSpecs, setSelectedSpecs] = useState([]);
    const [branches, setBranches] = useState([]);
    const [specs, setSpecs] = useState([]);
    const [flashMessage, setFlashMessage] = useState('');
    const [isPositiveMessage, setIsPositiveMessage] = useState(false);
    const [showFlash, setShowFlash] = useState(false);
    const [webUrl, setWebUrl] = useState('');
    const [hostUrl, setHostUrl] = useState('');

    const gitLabApi = new GitLabApi();

    useEffect(() => {
        const fetchBranches = async () => {
            const response = await gitLabApi.fetchBranchNames(gitConstants.studioProjectId);
            return response.data;
        }

        fetchBranches().then(branches => {
            setBranches(branches.map(branch => branch.name));
        }).catch(console.error)

    }, []);

    useEffect(() => {
        const fetchSpecFies = async () => {
            const response = await gitLabApi.getListOfSpecFiles(selectedBranch, gitConstants.studioProjectId);
            return response.data;
        }

        fetchSpecFies().then((files) => {
            const fetchedSpecs = files
                .filter(file => file.type === 'blob' && file.name.endsWith('.spec.ts'))
                .map(file => file.path.split('/')[file.path.split('/').length - 1].split('.')[0]);
            setSpecs(fetchedSpecs)
        }).catch(console.error);

        setSelectedSpecs([]);
    }, [selectedBranch]);

    const handleFlashMessageClick = () => {
        if (webUrl) {
            window.open(webUrl, '_blank');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const variables = {
                PROJECT_NAME: project,
                server,
            };

            if (hostUrl !== '') {
                variables.HOST_URL = hostUrl;
            }

            if (specs !== '') {
                variables.specs = selectedSpecs.map(s => s.value).join(',')
            }

            const response = await gitLabApi.triggerPipeline({
                gitlabProjectId: gitConstants.studioProjectId,
                ref: selectedBranch,
                variables
            });
            if (response.status === 201) {
                setFlashMessage('Pipeline triggered successfully.');
                setIsPositiveMessage(true);
                setWebUrl(response.data.web_url);
            } else {
                setFlashMessage('Failed to trigger pipeline.');
                setIsPositiveMessage(false);
            }
            setShowFlash(true);
            setTimeout(() => {
                setShowFlash(false);
            }, 10000);
        } catch (error) {
            console.error('Failed to trigger pipeline:', error);
            setFlashMessage('Error triggering pipeline.');
            setIsPositiveMessage(false);
            setShowFlash(true);
            setTimeout(() => {
                setShowFlash(false);
            }, 5000);
        }
    };

    return (
        <div className="container">
            <h1 className="title">Spec Run</h1>
            <form onSubmit={handleSubmit}>
                <label>
                    Host Url<InfoTooltip message={`Host url is used for Feature Branches`}/>
                    <div className="input-group">
                        <input
                            value={hostUrl}
                            onChange={e => setHostUrl(e.target.value)}
                            className="input-field"
                            placeholder="https://feature-branch-url.com"
                        />
                        <button type="button" className="clear-button" onClick={() => setHostUrl('')}>×</button>
                    </div>
                </label>
                <label>
                    Project
                    <div className="input-group">
                        <select value={project} onChange={e => setProject(e.target.value)} className="select-field">
                            {formOptions.projects.map(p => (
                                <option key={p.name} value={p.name}>{p.name}</option>
                            ))}
                        </select>
                    </div>
                </label>

                <label>
                    Specs <InfoTooltip message="Select one or multiple specs to run"/>
                    <div className="input-group">
                        <Select
                            value={selectedSpecs}
                            onChange={setSelectedSpecs}
                            options={specs.map(s => ({value: s, label: s}))}
                            isMulti
                            className="select-field"
                            required={true}
                        />
                    </div>
                </label>
                <label>
                    Server
                    <div className="input-group">
                        <select value={server} onChange={e => setServer(e.target.value)} className="select-field">
                            {formOptions.servers.map(s => (
                                <option key={s} value={s}>{s}</option>
                            ))}
                        </select>
                    </div>
                </label>
                <label>
                    Branch
                    <div className="input-group">
                        <select value={selectedBranch} onChange={e => setSelectedBranch(e.target.value)}
                                className="select-field">
                            {branches.map(s => (
                                <option key={s} value={s}>{s}</option>
                            ))}
                        </select>
                    </div>
                </label>
                <button type="submit" className="button">Trigger Spec Run</button>
            </form>
            {showFlash && (
                <div className={`flash-message ${isPositiveMessage ? 'positive' : 'negative'}`}
                     onClick={handleFlashMessageClick}>
                    {flashMessage}
                </div>
            )}
        </div>
    );
}

export default SpecRunPage;
