import axios from 'axios';
import config from '../config';

class GitLabApi {
    constructor() {
        this.baseUrl = config.GITLAB_API_URL;
        this.gitlabToken = config.GITLAB_API_TOKEN;
    }

    async triggerPipeline({gitlabProjectId, ref = 'master', variables}) {
        const url = `${this.baseUrl}/projects/${gitlabProjectId}/pipeline`;
        variables['trigger'] = 'johnkins';
        const data = {
            ref: ref,
            variables: Object.keys(variables).map(key => ({
                key: key.toUpperCase(),
                value: variables[key],
            }))
        };

        const headers = {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            'PRIVATE-TOKEN': this.gitlabToken,
        };

        try {
            return await axios.post(url, data, {headers});
        } catch (error) {
            console.error('Error triggering GitLab pipeline:', error);
            throw error;
        }
    }

    async fetchBranchNames(gitlabProjectId) {
        const url = `${this.baseUrl}/projects/${gitlabProjectId}/repository/branches?search=&per_page=50&sort=updated_desc`
        try {
            return axios.get(url, {
                headers: {
                    'PRIVATE-TOKEN': this.gitlabToken
                }
            });
        } catch (e) {
            console.error('Error getting GitLab branches:', e);
            throw e;
        }
    }

    async getListOfSpecFiles(branchName = 'master', gitlabProjectId, isWeb = true) {
        const url = `${this.baseUrl}/projects/${gitlabProjectId}/repository/tree?path=${isWeb ? 'specs/web' : 'specs'}/&recursive=true&ref=${branchName}&per_page=200`;
        try {
            return axios.get(url, {
                headers: {
                    'PRIVATE-TOKEN': this.gitlabToken
                }
            });
        } catch (e) {
            console.error('Error getting list of spec files branches:', e);
            throw e;
        }
    }
}

export default GitLabApi;
